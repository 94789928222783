.add_questions{
    position: fixed;
    bottom: 20px;
    left: 20px;
    font-size: 50px;
    cursor: pointer;
  }

  

  .surveytitle-container form {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: antiquewhite;
  }

  .surveytitle-container .title {
    display: flex;
    justify-content: space-between;
  }

  .surveytitle-container .title .delete-btn {
    background-color: red;
    color: white;
    padding: 4px 14px;
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid;
  }

  .surveytitle-container .title .update-btn {
    background-color: green;
    color: white;
    padding: 4px 14px;
    cursor: pointer;
    border: 1px solid;
  }

  .surveytitle-container p {
    width: 100%;
    height: fit-content;
    border: 1px solid #ccc;
    padding: 10px;
  }

  .list-group-item {
    display: flex !important;
    justify-content: space-between;
  }

  .list-group-item .edit {
    border: none;
    padding: 5px 10px;
  }

  .my-Modal-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

  .my-Modal-container .my-Modal {
    width: 500px;
    max-width: 100%;
  }

  .my-Modal-container .question-text{
    display: flex;
    justify-content: space-between;
  }

  .my-Modal-container .modal-body{
    max-height: 500px;
    overflow-y: scroll;
  }

  .my-Modal-container .my-Modal textarea,
  .my-Modal-container .my-Modal select
   {
    width: 100%;
  }
