.selected-day {
    border: 1px solid black;
    background-color: darkgray !important;
  }
  
      body{
      background: #edf1f5;
      margin-top:20px;
  }
  .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 0 solid transparent;
      border-radius: 0;
  }
  .btn-circle.btn-lg, .btn-group-lg>.btn-circle.btn {
      width: 50px;
      height: 50px;
      padding: 14px 15px;
      font-size: 18px;
      line-height: 23px;
  }
  .text-muted {
      color: #8898aa!important;
  }
  [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
      cursor: pointer;
  }
  .btn-circle {
      border-radius: 100%;
      width: 40px;
      height: 40px;
      padding: 10px;
  }
  .user-table tbody tr .category-select {
      max-width: 150px;
      border-radius: 20px;
  }
  
  .table-body-container .assign-img {
    max-width: 100%;
  }
  
  .main-container {
    position: relative;
  }
  
  .my-model-container {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
    
    .my-model-container .modal-body {
      max-height: 60vh;
      overflow-y: scroll;
    }
  
    .my-model-container .my-model {
      width: 500px;
      max-width: 100%;
    }
  
  
    .assign-survey .all-surveys .add-btn,
    .assign-survey .all-surveys .edit-btn,
    .assign-survey .all-surveys .delete-btn {
      color: white;
      background-color: green;
      padding: 5px;
      border-radius: 3px;
      font-size: 10px;
    }
  
    .assign-survey .all-surveys .delete-btn{
      background-color: red;
    }
  
    .assign-survey .schedule-details {
      width: 100%;
    }
  
    .assign-survey table {
      font-size: 10px;
    }
  
    .assign-survey .input-group{
      border: 1px solid #ccc;
      padding: 13px;
    }
  
    .my-model-container #groupname {
      width: 100%;
    }
  
  .add-group {
    position: absolute;
      top: 50px;
      left: 20px;
      font-size: 40px;
      cursor: pointer;
  }
  
  .fas {
    cursor: pointer;
  }
  
  .timing-form {
    position: fixed;
    z-index: 1000;
    width: 500px;
    min-height: 500px;
    background: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .timing-form .recuring_details {
    display: flex;
    flex-direction: column;
  }
  
  
  .timing-form .recuring_time_span {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  
  .timing-form .recuring_time_span div {
    font-size: 12px;
    border-radius: 0;
    padding: 4px 10px;
    margin-right: 15px;
    background-color: white;
    cursor: pointer;
  }
  
  
  .timing-form .recuring_details .days_container {
    display: flex;
    margin-top: 20px;
  }
  
  
  .timing-form .recuring_details .days_container div {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-right: 20px;
    cursor: pointer;
  }
  
  
  .timing-form .recuring_details .month_container {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  
  .timing-form .recuring_details .month_container div {
    background-color: white;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  
  .timing-form .recuring_details .year_container {
    margin-top: 20px;
  }
  
  
  .timing-form .recuring_details .year_container .years {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  
  .timing-form .recuring_details .year_container .years div {
    background-color: darkgray;
    padding: 4px 10px;
  }
  
  
  .timing-form .recuring_details .year_container .years div i {
    display: inline-block;
    margin-left: 10px;
    font-size: 15px;
    cursor: pointer;
  }
  
  
  .timing-form .recuring_details .year_container .add_year_container > div {
    width: 200px;
    display: flex;
    justify-content: space-around;
    border: 1px solid darkgray;
    padding: 10px 0;
    background-color: darkgray;
  }
  
  
  .timing-form .recuring_details .year_container .add_year_container > div > div {
    background-color: white;
    padding: 4px 10px;
    border: 1px solid;
    cursor: pointer;
  }
  
  .timing-form .recuring_details .year_container .add_year_container div input {
    width: 80px;
    padding: 4px 10px;
    border: 1px solid black;
  }
  
  
  .timing-form .recuring_details .okr_container {
    margin-top: 20px;
  }
  
  
  .timing-form .recuring_details .okr_container div {
    background-color: darkgray;
    width: fit-content;
    padding: 4px 10px;
  }
  
  .assign-survey tbody, 
  .assign-survey td, 
  .assign-survey tfoot, 
  .assign-survey th, 
  .assign-survey thead, 
  .assign-survey tr {
      border-color: inherit;
      border-style: none;
      border-width: 0;
  }
  